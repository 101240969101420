import React, { useCallback } from 'react'
import { Container, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// @ts-ignore
import { useFlexSearch } from 'react-use-flexsearch'
import useSearchParam from 'react-use/lib/useSearchParam'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from 'react-helmet'
import SearchSection from '../LandingPage/SearchSection'
import LandingPageSection from '../LandingPage/LandingPageSection'
import { PostLink, SearchPosts, SampleLink } from '../../types/landingPage'
import usePagination from '../../hooks/usePagination'
import useEvents from '../../hooks/useEvents'
import NeutralButton from '../Buttons/NeutralButton'
import NotFound from '../Errors/NotFound'

interface Props {
  localSearchPosts: SearchPosts
  variant?: string
}

const pageSize = 9
const pageSizeSamples = 12

const SearchPageTemplate: React.FC<Props> = ({ localSearchPosts, variant }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const searchQuery = useSearchParam('s') || ''
  const sendEvent = useEvents()

  const results = useFlexSearch(
    searchQuery,
    localSearchPosts.index,
    localSearchPosts.store,
    {
      tokenize: 'forward',
    }
  ) as PostLink[]
  // Flexisearch is untyped and I don't know where it's getting this string
  // from?
  // @ts-ignore
  const searchSamples: SampleLink[] = Object.values(localSearchPosts.store)

  const {
    elements: paginatedSamples,
    nextPage: nextPageSamples,
    hasMore: hasMoreSamples,
  } = usePagination(searchSamples, pageSizeSamples)

  const {
    elements: paginatedPosts,
    nextPage,
    hasMore,
  } = usePagination(results, pageSize)

  const handleNextPage = useCallback(() => {
    nextPage()
    sendEvent({
      event: 'Search Pagination Load More Click',
      variables: {
        pagination: {
          page: paginatedPosts.length / pageSize + 1,
          pageSize,
          totalItems: results.length,
        },
      },
    })
    /* eslint-disable-next-line  react-hooks/exhaustive-deps */
  }, [paginatedPosts.length, results.length, nextPage, sendEvent])

  const handleNextPageSamples = useCallback(() => {
    nextPageSamples()
    sendEvent({
      event: 'Search Pagination Load More Click',
      variables: {
        pagination: {
          page: paginatedSamples.length / pageSizeSamples + 1,
          pageSize,
          totalItems: paginatedSamples.length,
        },
      },
    })
    /* eslint-disable-next-line  react-hooks/exhaustive-deps */
  }, [
    paginatedPosts.length,
    results.length,
    nextPage,
    sendEvent,
    nextPageSamples,
    paginatedSamples.length,
  ])

  const showAll = variant === 'samples' && results.length === 0

  return (
    <>
      {searchQuery && (
        <Helmet>
          <title>
            {t('search.result', {
              length: results.length,
              term: searchQuery,
            })}
          </title>
        </Helmet>
      )}
      <Box className={classes.container}>
        <Box className={classes.containerInner}>
          <SearchSection
            searchResult={
              searchQuery
                ? t('search.result', {
                    length: results.length,
                    term: searchQuery,
                  })
                : ''
            }
            variant={variant}
          />
          {variant !== 'samples' && paginatedPosts.length === 0 ? (
            <Container className={classes.notFoundContainer} disableGutters>
              <NotFound showArticleText />
            </Container>
          ) : (
            <LandingPageSection
              links={showAll ? paginatedSamples : paginatedPosts}
              layout={variant === 'samples' ? 'gridExtraSmall' : 'grid'}
              limit={showAll ? paginatedSamples.length : paginatedPosts.length}
            />
          )}
          {(hasMore || (showAll && hasMoreSamples)) && (
            <Container className={classes.buttonContainer}>
              <NeutralButton
                onClick={showAll ? handleNextPageSamples : handleNextPage}
                variant="outlined"
              >
                {t('search.showMore')}
              </NeutralButton>
            </Container>
          )}
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: '100%',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  containerInner: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buttonContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 6% 3.75em 6%',

    '@media (min-width: 1456px)': {
      padding:
        '0 calc((100% - 91rem)/2 + 5.5rem) 3.75em calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  notFoundContainer: {
    width: '50%',
  },
}))

SearchPageTemplate.whyDidYouRender = true
export default SearchPageTemplate
