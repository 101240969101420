import React from 'react'
import { graphql, PageProps } from 'gatsby'
import SearchPageTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/SearchPage'
import { SearchPosts } from '@talentinc/gatsby-theme-ecom/types/landingPage'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import NavTestHeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/NavTestHeaderV2'

const Search: React.FC<
  PageProps<{ localSearchPosts: SearchPosts } & PageDataContextType>
> = (props) => {
  const { localSearchPosts, ...pageData } = props.data
  const { t } = useTranslation()

  return (
    <Layout pageTitle={t('search.pageTitle')} pageData={pageData}>
      <HeaderV2 showBlogNavigation />
      <NavTestHeaderV2 showBlogNavigation />
      <SearchPageTemplate localSearchPosts={localSearchPosts} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query SearchPage($brandName: String!) {
    localSearchPosts: localSearchPosts {
      index
      store
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    navTestHeaderV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "6yZRtzPjZxKCylBbpXF0oH" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

Search.whyDidYouRender = true
export default Search
